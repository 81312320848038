<template lang="">
    <div class="test">
        <a-row :gutter="[16, 16]" v-if="!isLoading && testObject">
            <a-col :xs="0" :sm="0" :lg="0" :xl="6" class="box-left">
                <QuestionMenu :type="'TEST'" :isSubmitTest="step === 'STARTED'" :onSubmitTest="submitTest" />
            </a-col>
            <a-col :xs="24" :sm="24" :lg="24" :xl="18">
                <div class="box-right">
                    <div class="test__start" v-if="step === 'READY'">
                        <Time :start="false" :time="testObject.time_limit" v-if="testObject.time_limit" />
                        <img src="../../../assets/images/practice-in-progress.svg" alt="practice-in-progress" />
                        <button class="btn" @click="setStepAction('STARTED')">{{ $t('test_page.start') }}</button>
                    </div>
                    <div class="test__content" v-else-if="step === 'STARTED'">
                        <div class="control">
                            <div
                                class="control__time"
                                :style="{
                                    justifyContent: testObject.time_limit ? 'space-between' : 'flex-end',
                                }"
                            >
                                <Time
                                    :start="true"
                                    :time="testObject.time_limit"
                                    :isStop="isStopCountdown"
                                    :onTimeOut="handleTimeOut"
                                    :onSetWorkTime="setWorkTime"
                                    v-if="testObject.time_limit"
                                />
                                <div class="submit">
                                    <button @click="submitTest">{{ $t('form.submit') }}</button>
                                </div>
                            </div>
                            <Progress :type="'TEST'" />
                        </div>
                        <QuestionList :type="'TEST'" />
                        <div class="mt-3">
                            <NoData v-if="!testObject.test_questions.length" />
                        </div>
                        <div class="text-center">
                            <button class="btn" @click="submitTest">{{ $t('form.submit') }}</button>
                        </div>
                    </div>
                </div>
            </a-col>
        </a-row>
        <Loading v-if="isLoading" />
        <a-modal v-model="isTimeOut" :centered="true" :closable="false" :maskClosable="false" :footer="false">
            <div class="test__timeOut">
                <img src="../../../assets/images/out-of-time.png" alt="out-of-time.png" />
                <h3>{{ $t('test_page.time_out') }}</h3>
                <div class="flex align-center">
                    <button class="btn-back" @click="handleBack">{{ $t('go_back') }}</button>
                    <button class="btn-submit" @click="attemptTestAction">{{ $t('form.submit') }}</button>
                </div>
            </div>
        </a-modal>
        <a-modal v-model="isWarning" :centered="true" :closable="false" :maskClosable="false" :footer="false">
            <div class="test__warning">
                <img src="../../../assets/images/warning.png" alt="warning.png" />
                <h3>{{ $t('test_page.please_answer_all_question') }}</h3>
                <div class="flex align-center">
                    <button class="btn-ok" @click="handleClickOK">OK</button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Time from './Time.vue';
import NoData from '../NoData';
import Progress from '../Progress/Progress.vue';
import Loading from '../Loading/Loading.vue';
import QuestionMenu from '../Questions/QuestionMenu.vue';
import QuestionList from '../Questions/QuestionList.vue';

import { mixinToasts, mixinLoadings } from '../../../mixins';

export default {
    mixins: [mixinToasts, mixinLoadings],
    components: {
        Time,
        NoData,
        Progress,
        Loading,
        QuestionMenu,
        QuestionList,
    },
    data() {
        return {
            isLoading: false,
            isTimeOut: false,
            isWarning: false,
            isStopCountdown: false,
            workTime: 0,
        };
    },
    computed: {
        ...mapState('testWeb', ['step', 'testObject', 'userAnswers']),
    },
    async mounted() {
        await this.resetState();
        await this.getTestAction();
    },
    methods: {
        ...mapActions('testWeb', ['getTest', 'attemptTest', 'setStep', 'resetState']),

        async getTestAction() {
            this.isLoading = true;
            await this.getTest({
                id: this.$route.params.id,
                onSuccess: (isSuccess, data) => {
                    if (!isSuccess) {
                        this.showError('error', data);
                    }
                },
            });
            this.isLoading = false;
        },

        async attemptTestAction() {
            this.isStopCountdown = true;
            this.showLoading();
            setTimeout(async () => {
                await this.attemptTest({
                    course_id: this.$route.query.course_id,
                    time: parseInt(this.workTime || 0),
                    onSuccess: (isSuccess, data) => {
                        if (isSuccess) {
                            this.isStopCountdown = false;
                            const { record_id, test_id } = data;
                            this.$router.push({ path: `/result-page/${record_id}`, query: { test_id } });
                        } else {
                            this.showError('error', data);
                        }
                    },
                });
                this.hideLoading();
            }, 500);
        },

        setStepAction(value) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setStep(value);
        },

        submitTest() {
            if (
                this.testObject.test_questions.length === 0 ||
                this.userAnswers.length < this.testObject.test_questions.length
            ) {
                this.isWarning = true;
                return;
            }

            this.$confirm({
                title: this.$t('form.submit'),
                content: this.$t('test_page.confirm_submit_test'),
                okText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                centered: true,
                onOk: () => {
                    this.attemptTestAction();
                },
                onCancel: () => {},
            });
        },

        setWorkTime(value) {
            this.workTime = value;
        },

        handleTimeOut() {
            this.isTimeOut = true;
        },

        handleBack() {
            window.history.back();
        },

        handleClickOK() {
            this.isWarning = false;
        },
    },
};
</script>

<style scoped lang="scss">
.ant-modal-body {
    width: 500px;
}

.test {
    & .btn {
        height: 40px;
        width: 180px;
        padding: 6px 8px;
        margin-top: 46px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        background: transparent;
        border: 2px solid #007aff;
        border-radius: 30px;
        color: #007aff;
        transition: all 0.3s ease;
    }
    & .btn:hover {
        background-color: rgba(25, 118, 210, 0.04);
    }
}

.box-left {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.test__start {
    background: #fff;
    border-radius: 15px;
    padding: 40px 10px 25px;
    text-align: center;
    & img {
        display: block;
        margin: 28px auto 0;
        width: 60%;
    }
}

.test__timeOut,
.test__warning {
    text-align: center;
    & img {
        width: 100px;
        margin-bottom: 20px;
    }
    & h3 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    & .btn-back,
    & .btn-submit,
    & .btn-ok {
        height: 40px;
        width: 120px;
        padding: 6px 8px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        background: transparent;
        border: 2px solid #5645ff;
        border-radius: 30px;
        color: #5645ff;
        transition: all 0.3s ease;
        &:hover {
            box-shadow: 0 1px 8px rgba(86, 69, 255, 0.5);
            transform: translateY(-2px);
        }
    }
    & .btn-back {
        margin-right: 12px;
    }
    & .btn-submit {
        background: #5645ff;
        color: #fff;
    }
    & .btn-ok {
        background: transparent;
        color: #000;
        border-color: #000;
        &:hover {
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
        }
    }
}

.test__content {
    & .control {
        background-color: #fff;
        border-radius: 15px;
        position: sticky;
        position: -webkit-sticky;
        top: 65px;
        z-index: 2;
        box-shadow: 0 6px 15px rgb(218 220 237 / 50%);
    }
    & .control .control__time {
        display: flex;
        align-items: center;
        justify-content: center;

        & .submit {
            display: none;
            padding: 20px;
            & button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                background: #e4e6ed;
                color: #383838;
                border: 2px solid #e4e6ed;
                border-radius: 20px;
                font-weight: 600;
                height: 30px;
                width: 98px;
            }
            & button:hover {
                border-color: #383838;
            }
        }
    }

    & .control .progress {
        display: none;
        margin-top: 0;
        padding: 0 20px 10px;
    }
}

@media screen and (max-width: 1200px) {
    .test__content {
        & .control .control__time {
            justify-content: space-between;
            & .submit {
                display: block;
            }
        }
        & .control .progress {
            display: flex;
        }
    }
}
</style>
