<template lang="">
    <div class="test-page">
        <ButtonBack />
        <Test />
    </div>
</template>

<script>
import ButtonBack from '../../components/Web/ButtonBack';
import Test from '../../components/Web/Test';

export default {
    components: {
        ButtonBack,
        Test,
    },
};
</script>

<style scoped lang="scss">
.test-page {
    width: 100%;
    max-width: 1470px;
    padding: 0 15px;
    margin: 80px auto 80px;
}
</style>
